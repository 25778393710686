













import { Component, Vue } from 'vue-property-decorator'
import Navigation from '@/components/Navigation.vue'
import { AppModule } from './store/module'
import RegisterModal from '@/components/RegisterModal.vue'
import DemoShowModal from '@/components/DemoShowModal.vue'

@Component({
  components: {
    Navigation,
    RegisterModal,
    DemoShowModal
  },
})
export default class App extends Vue {
  isShowRegisterModal = false

  isShowDemoShowModal = false

  created() {
    this.setDeviceClass()
    const isH5 = (document.body.clientWidth || document.documentElement.clientWidth) <= +process.env.VUE_APP_H5_WIDTH
    AppModule.setIsH5(isH5)
    window.addEventListener('resize', () => {
      AppModule.setIsH5(
        (document.body.clientWidth || document.documentElement.clientWidth) <= +process.env.VUE_APP_H5_WIDTH,
      )
    })

    function calculateFontSize() {
      const WIDTH = document.body.clientWidth || document.documentElement.clientWidth
      if (AppModule.isH5) {
        document.documentElement.style.fontSize = `${(process.env.VUE_APP_ROOT_VALUE / 375) * WIDTH}px`
      } else {
        document.documentElement.style.fontSize = `${(process.env.VUE_APP_ROOT_VALUE / 1240) * WIDTH}px`
      }
    }
    // 设置根字体大小
    calculateFontSize()
    window.onresize = calculateFontSize
  }

  mounted() {
    this.showRegisterModal()
  }

  /** 设置设备 class */
  setDeviceClass() {
    const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    document.documentElement.classList.add(isIos ? 'ios' : 'android')
  }

  // 45s后显示登记弹窗
  showRegisterModal() {
    if (window['__PRERENDER_INJECTED']) return
    const timer = setTimeout(() => {
      this.isShowRegisterModal = true;
      /* eslint-disable @typescript-eslint/camelcase */
      window['gtag']('event', 'show', {
        event_category: 'modal',
        event_label: 'register'
      })
      clearTimeout(timer)
    }, 45000)
  }
}
