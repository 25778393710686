import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './components/global'
import './plugins'
import './directives'

// class-component-hooks.js
import Component from 'vue-class-component'

// Register the router hooks with their names
Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate', // for vue-router 2.2+
])

Vue.config.productionTip = false

Vue.prototype.$isH5 =
  (document.body.clientWidth || document.documentElement.clientWidth) <= +process.env.VUE_APP_H5_WIDTH

console.log('ok, in main')

new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  },
}).$mount('#app')
