






























































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AppModule } from '@/store/module'
import { scrollToElem } from '@/utils/scroll'

@Component
export default class Navigation extends Vue {
  /** 是否展开导航栏 */
  isOpen = false

  /** 导航栏列表 */
  navs = [
    {
      type: 'list',
      name: 'PinMe 餐飲解決方案',
      children: [
        {
          name: '咖啡/奶茶/薯條炸雞/快餐便當',
          routeName: 'snacks',
          active: false,
        },
        {
          name: '茶餐廳/西餐廳/酒樓',
          routeName: 'restaurant',
          active: false,
        },
        {
          name: '車仔麵/米線店',
          routeName: 'pasta',
          active: false,
        },
        {
          name: 'FoodCourt',
          routeName: 'foodcourt',
          active: false,
        },
        {
          name: '中央廚房+外賣自取點/外賣送餐',
          routeName: 'takeaway',
          active: false,
        },
      ],
      isShow: true,
    },
    {
      type: 'list',
      name: '賣點',
      children: [
        {
          name: '一部Pos處理多家外賣平台訂單',
          routeName: 'deliveryPlatform',
          active: false,
          hover: false
        },
        {
          name: '強大會員系統提升顧客的忠誠度',
          routeName: 'member',
          active: false,
          hover: false
        },
        {
          name: '強大的營銷功能，幫助餐廳提升營業額',
          routeName: 'discount',
          active: false,
          hover: false
        }
      ],
      isShow: false,
      shit: 'shit'
    },
    {
      type: 'link',
      name: '等味排隊',
      routeName: 'queue',
    },
    {
      type: 'link',
      name: '系統配置',
      routeName: 'systemKit',
    },
    {
      type: 'external_link',
      name: '餐廳營運經驗分享',
      href: '/articles'
    },
    {
      type: 'hr',
      name: '',
    },
    {
      type: 'link',
      name: '關於我們',
      routeName: 'about',
    },
  ]

  restaurantTypes = [
    {
      name: '咖啡/飲品/快餐店',
      routeName: 'snacks',
      active: false,
      hover: false
    },
    {
      name: '茶餐廳/冰室/西餐/酒楼',
      routeName: 'restaurant',
      active: false,
      hover: false
    },
    {
      name: '沙律/米線/DIY餐牌',
      routeName: 'pasta',
      active: false,
      hover: false
    },
    {
      name: 'Foodcourt 美食廣場',
      routeName: 'foodcourt',
      active: false,
      hover: false
    },
    {
      name: '中央廚房/虛擬餐廳',
      routeName: 'takeaway',
      active: false,
      hover: false
    }
  ]

  saleTypes = [
    {
      name: '一部Pos處理多家外賣平台訂單',
      routeName: 'deliveryPlatform',
      active: false,
      hover: false
    },
    {
      name: '強大會員系統提升顧客的忠誠度',
      routeName: 'member',
      active: false,
      hover: false
    },
    {
      name: '強大的營銷功能，幫助餐廳提升營業額',
      routeName: 'discount',
      active: false,
      hover: false
    }
  ]

  get isH5() {
    return AppModule.isH5
  }

  @Watch('$route', { immediate: true })
  onRouteChanged() {
    this.isOpen = false
    this.navs = this.navs.map(item => {
      if (item.type !== 'list') return item
      let isParent = false
      /*
      item.children = item.children.map(child => {
        child.active = false
        if (child.routeName === this.$route.name) {
          item.isShow = true
          child.active = true
          isParent = true
        }
        return child
      })
      */
      item.children.forEach((child) => {
        child.active = false
        if (child.routeName === this.$route.name) {
          item.isShow = true
          child.active = true
          isParent = true
        }
      })
      if (isParent) {
        item.isShow = true
      } else {
        item.isShow = false
      }
      return item
    })

    this.activateDropdownItem()
  }

  activateDropdownItem() {
    this.restaurantTypes = this.restaurantTypes.map(item => {
      item.active = false
      if (item.routeName === this.$route.name) {
        item.active = true
      }
      return item
    })
    this.saleTypes = this.saleTypes.map(item => {
      item.active = false
      if (item.routeName === this.$route.name) {
        item.active = true
      }
      return item
    })
  }

  mounted() {
    document.body.appendChild(this.$refs.sideWrap as Element)
  }

  beforeDestroy() {
    document.body.removeChild(this.$refs.sideWrap as Element)
  }

  showChildren(index: number) {
    this.navs = this.navs.map((item, i) => {
      item.isShow = i === index ? !item.isShow : false
      return item
    })
  }

  /** 滚动到联系我们 */
  gotoContact() {
    this.isOpen = false
    scrollToElem('#contact', 500, 80)
  }

  /** 跳转到中台 */
  gotoCloudPlatform() {
    window.location.href = 'https://cloud.pin2eat.com'
  }
}
