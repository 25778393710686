module.exports = [
  {
    // 首页
    path: '/',
    name: 'home',
    component: () => import('@/views/home/Home.vue'),
  },
  {
    // 场景页
    path: '/program',
    name: 'program',
    component: () => import('@/views/program/index.vue'),
    children: [
      {
        // 咖啡/奶茶
        path: 'snacks',
        name: 'snacks',
        component: () => import('@/views/program/Snacks.vue'),
      },
      {
        // 车仔面
        path: 'pasta',
        name: 'pasta',
        component: () => import('@/views/program/Pasta.vue'),
      },
      {
        // 茶餐廳
        path: 'restaurant',
        name: 'restaurant',
        component: () => import('@/views/program/Restaurant.vue'),
      },
      {
        // FoodCourt
        path: 'foodcourt',
        name: 'foodcourt',
        component: () => import('@/views/program/Foodcourt.vue'),
      },
      {
        // 中央厨房+外卖自取
        path: 'takeaway',
        name: 'takeaway',
        component: () => import('@/views/program/Takeaway.vue'),
      }
    ]
  },
  {
    // 卖点
    path: '/sale',
    name: 'sale',
    component: () => import('@/views/sale/index.vue'),
    children: [
      {
        // 外卖平台
        path: 'deliveryPlatform',
        name: 'deliveryPlatform',
        component: () => import('@/views/sale/DeliveryPlatform.vue')
      },
      {
        // 会员系统
        path: 'member',
        name: 'member',
        component: () => import('@/views/sale/Member.vue')
      },
      {
        // 优惠
        path: 'discount',
        name: 'discount',
        component: () => import('@/views/sale/Discount.vue')
      }
    ]
  },
  {
    // 系統配置
    path: '/system-kit',
    name: 'systemKit',
    component: () => import('@/views/kit/SystemKit.vue'),
  },
  {
    // 关于我们
    path: '/about',
    name: 'about',
    component: () => import('@/views/others/About.vue'),
  },
  {
    // 隐私政策
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import('@/views/others/PrivacyPolicy.vue'),
  },
  {
    // 使用条款
    path: '/use-policy',
    name: 'use-policy',
    component: () => import('@/views/others/UsePolicy.vue'),
  },
  {
    // 获取智慧餐廳演示
    path: '/getdemo',
    name: 'getdemo',
    component: () => import('@/views/others/GetDemo.vue'),
  },
  {
    // 查看智慧餐廳演示
    path: '/demoshow',
    name: 'demoshow',
    component: () => import('@/views/others/DemoShow.vue'),
  },
  {
    // 等味排队
    path: '/queue',
    name: 'queue',
    component: () => import('@/views/queue/index.vue')
  }
]
