window.requestAnimationFrame =
  window.requestAnimationFrame || window.webkitRequestAnimationFrame

const QuadEaseIn = (t, b, c, d) => c * ((t = t / d - 1) * t * t + 1) + b

//  滚动到具体位置
const scrollTo = (end, time = 500, wrapElem?: HTMLDivElement) => {
  const scrollTop = wrapElem
    ? wrapElem.scrollTop
    : window.pageYOffset || document.documentElement.scrollTop
  const b = scrollTop
  const c = end - b
  const d = time
  let start = null

  function setScrollTop(top, elem) {
    if (elem) {
      elem.scrollTop = top
    } else {
      document.body.scrollTop = top
      document.documentElement.scrollTop = top
    }
  }

  return new Promise(resolve => {
    function step(timeStamp) {
      if (start === null) start = timeStamp
      const progress = timeStamp - start
      if (progress < time) {
        const st = QuadEaseIn(progress, b, c, d)
        setScrollTop(st, wrapElem)
        window.requestAnimationFrame(step)
      } else {
        setScrollTop(end, wrapElem)
        resolve(end)
      }
    }
    window.requestAnimationFrame(step)
  })
}

//  滚动到顶部
const scrollToTop = (time: number, wrapElem?: HTMLDivElement) => {
  time = typeof time === 'number' ? time : 800
  return scrollTo(0, time, wrapElem)
}

//  滚动到某元素
const scrollToElem = (
  elemSelector: string,
  time?: number,
  offset?: number,
  wrapElem?: HTMLDivElement,
) => {
  const elem = document.body.querySelector(elemSelector)
  if (!elem) return
  let top = 0
  if (wrapElem) {
    top = elem.getBoundingClientRect().top - wrapElem.offsetTop
  } else {
    top =
      elem.getBoundingClientRect().top +
      (window.pageYOffset || document.documentElement.scrollTop) -
      (document.documentElement.clientTop || 0)
  }
  return scrollTo(top - (offset || 0), time, wrapElem)
}

export { scrollTo, scrollToTop, scrollToElem }
