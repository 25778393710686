import Vue from 'vue'
import Router from 'vue-router'
import routes from './main'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// 20231119 update
const titles = {
  home: '香港餐飲業首選！PinMe POS-智慧雲端餐廳系統｜提升營運效率、省成本、做更多生意',
  snacks: 'PinMe餐飲POS，飲品店/咖啡店/快餐店/外賣店all in one餐廳系統',
  restaurant: 'PinMe餐飲POS，茶餐廳/冰室/西餐/酒樓/中菜館all in one餐廳系統',
  pasta: 'PinMe餐飲POS，車仔麵/米線店/中式粉面店all in one餐廳系統',
  foodcourt: 'PinMe餐飲POS，Foodcourt 餐飲大時代all in one餐廳系統',
  takeaway: 'PinMe餐飲POS，雲端廚房/虛擬餐廳all in one系統',
  deliveryPlatform: '一部Pos處理多家外賣平台訂單，已對接Foodpanda、Deliveroo外賣平台',
  member: '內含強大的餐廳會員管理系統，支持電子印花、優惠券、會員價等餐廳常用工具',
  discount: '提供可靈活設置的餐飲優惠券、折扣券、兌換券、特價菜等強大的營銷工具',
  systemKit: '【餐廳會員系統，限時免費申請】PinMe智慧雲端餐廳POS系統｜功能清單及介紹',
  queue: '香港餐飲排隊系統 “等味”，為香港餐飲行業提供方便、高效的餐飲排隊服務。支持遠程線上取號、餐廳大屏幕取號、方便快捷的餐飲排隊管理。'
}
const description = {
  home: 'PinMe雲端餐飲Pos｜香港餐飲行業都在用的Pos系統，支持茶餐廳、拉麵或米線店、咖啡或茶飲店、西餐、酒樓、foodcourt等多種餐廳業態。提供專業的餐飲管理、餐廳電子流程管理、QR code落單、支持堂食、外賣、自取、餐飲會員系統、強大餐飲營銷工具、全面的餐飲數據報表，一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台。PinMe專注香港餐飲數碼化，提升餐廳營運效率。',
  snacks: '開咖啡店、茶飲店、快餐店、外賣店，就用PinMe餐飲Pos系統。簡單輕量，支持QR code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
  restaurant: '開中式茶餐廳、冰室、西餐廳，就用PinMe餐飲Pos系統。支持複雜種類的電子餐單，豐富的菜式模型可選，支持qr code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
  pasta: '開米線店、車仔麵、粉面類型餐廳，就用PinMe餐飲Pos系統。支持超級自由搭配的電子餐單模型，允許顧客根據餐廳的設置，靈活自由搭配，支持QR code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。', 
  foodcourt: '開foodcourt就用pinme餐饮pos系统。支持“檔口收款”和“集中櫃台收款”，支持foodcourt的電子餐單功能、QR code落單。支持外賣配送、外賣自取業務，同時對接keeta、foodpanda、deliveroo等本地流行外賣平台。', 
  takeaway: '開虛擬餐廳/中央廚房，就用PinMe雲端餐飲Pos系統。提供強大的online餐廳主頁和電子餐單功能。支持外賣配送、外賣自取業務，同時對接keeta、foodpanda、deliveroo等本地流行外賣平台。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
  deliveryPlatform: 'PinMe雲餐餐飲pos｜一部pos管理所有外賣平台訂單，同時對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。支持菜單同步至外賣平台、訂單實時同步，集中處理，數據集中管理。',
  member: 'PinMe雲餐餐飲pos｜強大的會員管理CRM系統，支持會員歡迎券包、會員生日禮、會員價、會員印花累積與兌換功能。幫助餐廳留住老顧客，穩定提升餐廳的營業額。同時支持強大的會員數據分析功能，幫助餐廳發現最有價值的顧客，並留住顧客。同時也是獨立、專屬、安全的會員管理系統，數據隔離、信息安全。', 
  discount: 'PinMe雲餐餐飲pos｜強大的餐廳營銷模塊，支持豐富且可靈活配置的優惠券、兌換券、特價菜等營銷工具，幫助餐廳建立有效的營銷方案，並能合理控制促銷成本。', 
  systemKit: 'PinMe雲餐餐飲pos｜系統版本及配置表，PinMe餐飲pos具豐富和備強大的功能，可適應不同類型、不同規模的餐廳需求。同時面向大型連鎖餐廳開放標準化訂單數據接口。本頁可查詢PinMe pos系統價格、pos系統功能,餐廳可根據自身需求選擇適合的版本。',
  queue: '排隊系統, 餐飲排隊, restaurant queuing system, 餐廳網上取號, 餐廳排號, 餐廳排隊大屏幕, 餐廳管理'
}
const keywords = {
  home: `餐飲POS, 餐廳POS, 雲端POS,點餐POS, 餐飲業, 開餐廳, 茶餐廳POS, 奶茶店POS, 咖啡店POS, 酒樓POS, 西餐廳POS, POS軟體, 堂食點餐, 外賣店, 車仔麵, 火鍋店, 粉麵店, 飲品店, QR CODE 落單, 二維碼點餐, 自助點餐, 掃碼點餐, 手機落單, 自助落單, 二維碼落單, 外賣自取, 餐廳外賣, 餐廳外帶, 外帶系統, 外賣系統, 餐廳會員系統, 餐廳CRM系統, 香港餐飲`,
  snacks: '飲品店,咖啡店,快餐店,外賣店',
  restaurant: '茶餐廳,冰室,西餐,酒樓,中菜館',
  pasta: '車仔麵,米線店,中式粉面店',
  foodcourt: 'Foodcourt,餐飲大時代',
  takeaway: '雲端廚房,虛擬餐廳',
  deliveryPlatform: '外賣平台,Foodpanda,Deliveroo',
  member: '電子印花,優惠券,會員價',
  discount: '優惠券,折扣券,兌換券,特價菜',
  systemKit: 'POS系統,功能清單,介紹',
  queue: '餐廳排隊, 餐飲排隊系統, restaurant queuing system, 餐廳網上取號, 餐廳排號, 餐廳排隊大屏幕, 餐廳管理'
}
const schemas = {
  home: {
    '@context': 'https://www.pin2eat.com/',
    '@type': 'Organization',
    'url': 'https://www.pin2eat.com/',
    'logo': 'https://www.pin2eat.com/img/icons/favicon-32x32.png'
  },
  snacks: {
    '@context': 'https://www.pin2eat.com/program/snacks',
    '@type': 'Product',
    'name': '飲品店、咖啡店、快餐店、外賣店pos系統',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '開咖啡店、茶飲店、快餐店、外賣店，就用PinMe餐飲Pos系統。簡單輕量，支持qr code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  restaurant: {
    '@context': 'https://www.pin2eat.com/program/restaurant',
    '@type': 'Product',
    'name': '茶餐廳/冰室/西餐廳/酒樓/中菜館pos系統',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '開中式茶餐廳、冰室、西餐廳，就用PinMe餐飲Pos系統。支持複雜種類的電子餐單，豐富的菜式模型可選，支持qr code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  pasta: {
    '@context': 'https://www.pin2eat.com/program/pasta',
    '@type': 'Product',
    'name': '車仔麵店/米線店/中式粉麵店pos系統',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '開米線店、車仔麵、粉面類型餐廳，就用PinMe餐飲Pos系統。支持超級自由搭配的電子餐單模型，允許顧客根據餐廳的設置，靈活自由搭配，支持qr code落單，支持堂食/外賣/自取，支持流行的支付方式。一部pos對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  foodcourt: {
    '@context': 'https://www.pin2eat.com/program/foodcourt',
    '@type': 'Product',
    'name': 'Foodcourt美食廣場pos系統',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '數碼化的Foodcourt營運，便於輕鬆管理每個舖位的音韻。支持QR code點餐，無需排隊。支持櫃面自取、自取外送等多種生意模式，提升交易額20%',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  takeaway: {
    '@context': 'https://www.pin2eat.com/program/takeaway',
    '@type': 'Product',
    'name': '中央廚房+外賣自取點/外賣送餐pos系統',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '開虛擬餐廳/中央廚房，就用PinMe雲端餐飲Pos系統。提供強大的online餐廳主頁和電子餐單功能。支持外賣配送、外賣自取業務，同時對接keeta、foodpanda、deliveroo等本地流行外賣平台。另可搭載餐飲會員CRM系統和營銷工具，吸引新顧客、留住老顧客。',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  deliveryPlatform: {
    '@context': 'https://www.pin2eat.com/sale/deliveryPlatform',
    '@type': 'Article',
    'headline': '一部POS管理所有外賣平臺訂單',
    'author': {
      '@type': 'Organization',
      'name': 'PinMe'
    },
    'datePublished': '2020-08-01',
    'description': 'PinMe雲餐餐飲pos｜一部pos管理所有外賣平台訂單，同時對接keeta、foodpanda、deliveroo等本地流行外賣平台，幫助餐廳提升營業額。支持菜單同步至外賣平台、訂單實時同步，集中處理，數據集中管理。'
  },
  member: {
    '@context': 'https://www.pin2eat.com/sale/member',
    '@type': 'Article',
    'headline': '強大會員系統提升顧客的忠誠度',
    'author': {
      '@type': 'Organization',
      'name': 'PinMe'
    },
    'datePublished': '2020-08-01',
    'description': 'PinMe雲餐餐飲pos｜強大的會員管理CRM系統，支持會員歡迎券包、會員生日禮、會員價、會員印花累積與兌換功能。幫助餐廳留住老顧客，穩定提升餐廳的營業額。同時支持強大的會員數據分析功能，幫助餐廳發現最有價值的顧客，並留住顧客。同時也是獨立、專屬、安全的會員管理系統，數據隔離、信息安全。'
  },
  discount: {
    '@context': 'https://www.pin2eat.com/sale/discount',
    '@type': 'Article',
    'headline': '強大的營銷功能，幫助餐廳提升營業額',
    'author': {
      '@type': 'Organization',
      'name': 'PinMe'
    },
    'datePublished': '2020-08-01',
    'description': 'PinMe雲餐餐飲pos｜強大的餐廳營銷模塊，支持豐富且可靈活配置的優惠券、兌換券、特價菜等營銷工具，幫助餐廳建立有效的營銷方案，並能合理控制促銷成本。'
  },
  queue: {
    '@context': 'https://www.pin2eat.com/program/takeaway',
    '@type': 'Product',
    'name': '等味排隊',
    'image': 'https://spics.wantu.cn/922af80d805c8240b22afc76ea2d87e0.png',
    'description': '排隊系統, 餐飲排隊, restaurant queuing system, 餐廳網上取號, 餐廳排號, 餐廳排隊大屏幕, 餐廳管理',
    'brand': {
      '@type': 'Brand',
      'name': 'PinMe'
    }
  },
  systemKit: {
    '@context': 'https://www.pin2eat.com/system-kit',
    '@type': 'Article',
    'headline': '根據你的餐廳類型選擇適合的系統',
    'author': {
      '@type': 'Organization',
      'name': 'PinMe'
    },
    'datePublished': '2020-08-01',
    'description': 'PinMe雲餐餐飲pos｜系統版本及配置表，可根據自身需求選擇適合的版本。'
  }
}

router.beforeEach((to, from, next) => {
  document.title = titles[to.name] || titles['home']
  const metaDescription = document.querySelector('meta[name="description"]')
  if (metaDescription) {
    metaDescription.setAttribute('content', description[to.name] || description['home'])
  }
  const metaKeywords = document.querySelector('meta[name="keywords"]')
  if (metaKeywords) {
    metaKeywords.setAttribute('content', keywords[to.name] || keywords['home'])
  }
  // 2024 seo
  // schema
  const schemaTag = document.querySelector('#schema-tag')
  if (schemaTag) {
    schemaTag.innerHTML = JSON.stringify(schemas[to.name] || schemas['home'])
  }
  // canonical 
  const rel = document.querySelector('link[rel="canonical"]')
  if (rel) {
    rel.setAttribute('href', 'https://www.pin2eat.com' + to.fullPath)
  }
  // og title
  const ogTitle = document.querySelector('meta[property="og:title"]')
  if (ogTitle) {
    ogTitle.setAttribute('content', titles[to.name] || titles['home'])
  }
  // og description
  const ogDescription = document.querySelector('meta[property="og:description"]')
  if (ogDescription) {
    ogDescription.setAttribute('content', description[to.name] || description['home'])
  }
  // og url
  const ogUrl = document.querySelector('meta[property="og:url"]')
  if (ogUrl) {
    ogUrl.setAttribute('content', 'https://www.pin2eat.com' + to.fullPath)
  }
  // twitter card
  const twTitle = document.querySelector('meta[name="twitter:title"]')
  if (twTitle) {
    twTitle.setAttribute('content', titles[to.name] || titles['home'])
  }
  // twitter description
  const twDescription = document.querySelector('meta[name="twitter:description"]')
  if (twDescription) {
    twDescription.setAttribute('content', description[to.name] || description['home'])
  }
  // twitter url
  const twUrl = document.querySelector('meta[name="twitter:url"]')
  if (twUrl) {
    twUrl.setAttribute('content', 'https://www.pin2eat.com' + to.fullPath)
  }

  next()
})

export default router
